import React from 'react'
import './services.css'
import { ServicesPrice, ServicesTime } from '../../Assets/Images/Services'
import ContactServices from '../../Components/ContactServices/ContactServices'

const Services = () => {
  return (
    <div className="services_block" id={'services'}>
      <div className="customContainer">
        <div className="minicurs_title_block">
          <div className="minicurs_title_line"></div>
          <h2 className="services_title">Послуги</h2>
          <div className="minicurs_title_line"></div>
        </div>
        <div className="services_content">
          <div className="services_card">
            <div>
              <h2 className="services_card_title">
                Індивідуальна консультація
              </h2>
            </div>
            <div>
              <p className="services_card_text">
                Допомога у вирішенні питань: кредити, інвестування, банки,
                страхування, фондовий ринок, реєстрації, заведення/виведення
                коштів, верифікації та інше…
              </p>
            </div>
            <div className="services_time_block">
              <img
                className="services_card_icon"
                src={ServicesTime}
                alt="ServicesTime"
              />
              <p className="services_card_time">60хв</p>
              <img
                className="services_card_icon"
                src={ServicesPrice}
                alt="ServicesPrice"
              />
              <p className="services_card_price">$25</p>
            </div>
          </div>
          <div className="services_card">
            <div>
              <h2 className="services_card_title">Фінансова діагностика</h2>
            </div>
            <div>
              <p className="services_card_text">
                Оптимізація особистих фінансів, скрінінг теперішньої ситуації та
                визначення шляхів її покращення: доходи/витрати, активи/пасиви,
                фінансові цілі, можливості, шляхи руху вперед.
              </p>
            </div>
            <div className="services_time_block">
              <img
                className="services_card_icon"
                src={ServicesTime}
                alt="ServicesTime"
              />
              <p className="services_card_time">60хв</p>
              <img
                className="services_card_icon"
                src={ServicesPrice}
                alt="ServicesPrice"
              />
              <p className="services_card_price">$50</p>
            </div>
          </div>
          <div className="services_card">
            <div>
              <h2 className="services_card_title">
                Проведення корпоративного вебінару
              </h2>
            </div>
            <div>
              <p className="services_card_text">
                Вебінар/семінар по темі фінансової грамотності та інвестування
                для колективу. Подальший супровід.
              </p>
            </div>
            <div>
              <div className="services_time_block">
                <img
                  className="services_card_icon"
                  src={ServicesTime}
                  alt="ServicesTime"
                />
                <p className="services_card_time">60хв</p>
                <img
                  className="services_card_icon"
                  src={ServicesPrice}
                  alt="ServicesPrice"
                />
                <p className="services_card_price">$100</p>
              </div>
              <p className="services_corporate_text">
                *при замовленні від 3 заходів - $70
              </p>
            </div>
          </div>
          <div className="services_card">
            <div>
              <h2 className="services_card_title">
                Проведення гри "Cash flow" (для компанії до 7 осіб)
              </h2>
            </div>
            <div>
              <p className="services_card_text">
                Мета гри - проаналізувати свої життєві та фінансові стратегії,
                виявити зони для розвитку і поставити перед собою нові цілі.
              </p>
            </div>
            <div className="services_time_block">
              <img
                className="services_card_icon"
                src={ServicesTime}
                alt="ServicesTime"
              />
              <p className="services_card_time">5год</p>
              <img
                className="services_card_icon"
                src={ServicesPrice}
                alt="ServicesPrice"
              />
              <p className="services_card_price">$200</p>
            </div>
          </div>
          <div className="services_card">
            <div>
              <h2 className="services_card_title">
                Розробка особистого фінансового плану + супровід
              </h2>
            </div>
            <div>
              <p className="services_card_text">
                Фінансовий аналіз/діагностика - 60 хв, консультація - 90 хв, 4
                сервісні зустрічі протягом року по 60 хв, поточні консультації.
                Для тих, хто хоче досягнути своїх цілей максимально швидко
              </p>
            </div>
            <div className="services_time_block">
              <img
                className="services_card_icon"
                src={ServicesTime}
                alt="ServicesTime"
              />
              <p className="services_card_time">1 рік</p>
              <img
                className="services_card_icon"
                src={ServicesPrice}
                alt="ServicesPrice"
              />
              <p className="services_card_price">$200</p>
            </div>
          </div>
          <div className="services_card">
            <div>
              <h2 className="services_card_title_form">Онлайн запис</h2>
            </div>
            <div>
              <ContactServices />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
