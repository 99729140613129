import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './Reviews.module.sass'
import {
  Review1,
  Review2,
  Review3,
  Review4,
  Review5,
  Review6,
  Review7,
  Review8,
  Review9,
  Review10,
  Review11,
  Review12,
} from '../../Assets/Images/Reviews/Reviews'
// import Title from "../../Components/Title/Title";

// Компоненти для стрілок
const PrevArrow = ({ onClick }) => (
  <svg
    className={`${styles.slider_button} ${styles.prevButton}`}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="75"
    viewBox="0 0 87 75"
    fill="none"
  >
    <path
      d="M41.6313 51.4261L28.4309 38.5712C28.2742 38.4182 28.1635 38.2524 28.0987 38.0739C28.0329 37.8953 28 37.704 28 37.5C28 37.296 28.0329 37.1047 28.0987 36.9261C28.1635 36.7476 28.2742 36.5818 28.4309 36.4288L41.6313 23.5356C41.9969 23.1785 42.4539 23 43.0023 23C43.5507 23 44.0207 23.1913 44.4124 23.5739C44.8041 23.9565 45 24.4028 45 24.9129C45 25.423 44.8041 25.8694 44.4124 26.252L32.8963 37.5L44.4124 48.748C44.778 49.1051 44.9608 49.5448 44.9608 50.0672C44.9608 50.5906 44.765 51.0435 44.3733 51.4261C43.9816 51.8087 43.5246 52 43.0023 52C42.48 52 42.023 51.8087 41.6313 51.4261Z"
      fill="#09C4FF"
    />
    <circle
      cx="37.5"
      cy="37.5"
      r="36"
      transform="rotate(180 37.5 37.5)"
      stroke="#09C4FF"
      stroke-width="3"
    />
    <line
      x1="85.5"
      y1="37.5"
      x2="63.5"
      y2="37.5"
      stroke="#09C4FF"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>

  // <svg
  //   className={`${styles.slider_button} ${styles.prevButton}`}
  //   onClick={onClick}
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="31"
  //   height="41"
  //   viewBox="0 0 31 41"
  //   fill="none"
  // >
  //   <path
  //     d="M27.9152 37.377L5.74131 20.3059L27.9152 3.2348"
  //     stroke="#007EC6"
  //     stroke-width="7"
  //   />
  // </svg>
)

const NextArrow = ({ onClick }) => (
  <svg
    className={`${styles.slider_button} ${styles.nextButton}`}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="75"
    viewBox="0 0 87 75"
    fill="none"
  >
    <path
      d="M45.3687 23.5739L58.5691 36.4288C58.7258 36.5818 58.8365 36.7476 58.9013 36.9261C58.9671 37.1047 59 37.296 59 37.5C59 37.704 58.9671 37.8953 58.9013 38.0739C58.8365 38.2524 58.7258 38.4182 58.5691 38.5712L45.3687 51.4644C45.0031 51.8215 44.5461 52 43.9977 52C43.4493 52 42.9793 51.8087 42.5876 51.4261C42.1959 51.0435 42 50.5972 42 50.0871C42 49.577 42.1959 49.1306 42.5876 48.748L54.1037 37.5L42.5876 26.252C42.222 25.8949 42.0392 25.4552 42.0392 24.9328C42.0392 24.4094 42.235 23.9565 42.6267 23.5739C43.0184 23.1913 43.4754 23 43.9977 23C44.52 23 44.977 23.1913 45.3687 23.5739Z"
      fill="#09C4FF"
    />
    <circle cx="49.5" cy="37.5" r="36" stroke="#09C4FF" stroke-width="3" />
    <line
      x1="1.5"
      y1="37.5"
      x2="23.5"
      y2="37.5"
      stroke="#09C4FF"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
)

const Reviews = () => {
  const images = [
    Review1,
    Review2,
    Review3,
    Review4,
    Review5,
    Review6,
    Review7,
    Review8,
    Review9,
    Review10,
    Review11,
    Review12,
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 720, // Ширина, при якій застосовуються ці налаштування
        settings: {
          slidesToShow: 1, // Показуємо лише 1 слайд при ширині до 720px
        },
      },
    ],
  }

  return (
    <div className={styles.reviews_block} id={'reviews'}>
      <div className="customContainer">
        <div className="minicurs_title_block">
          <div className="minicurs_title_line"></div>
          <h2 className={styles.reviews_title}>
            Відгуки <span className="minicurs_title_span">учасників</span>{' '}
          </h2>
          <div className="minicurs_title_line"></div>
        </div>
      </div>
      <div className={styles.sliderContainer}>
        {/*<Title first={'Відгуки'} second={'Учасників'} third={''}/>*/}
        <div className={styles.slider}>
          <Slider {...settings}>
            {images.map((src, index) => (
              <div key={index} className={styles.slider_item}>
                <img
                  src={src}
                  alt={`Slide ${index}`}
                  className={styles.slider_image}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default Reviews

// import React, { useState } from 'react'
// import { useSwipeable } from 'react-swipeable'
// import {
//   Review1,
//   Review2,
//   Review3,
//   Review4,
//   Review5,
//   Review6,
//   Review7,
//   Review8,
//   Review9,
//   Review10,
//   Review11,
//   Review12,
// } from '../../Assets/Images/Reviews/Reviews'
// import './reviews.sass'
//
// const images = [
//   Review1,
//   Review2,
//   Review3,
//   Review4,
//   Review5,
//   Review6,
//   Review7,
//   Review8,
//   Review9,
//   Review10,
//   Review11,
//   Review12,
// ]
//
// const Slider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0)
//
//   const handleNext = () => {
//     if (currentIndex < images.length - 2) {
//       setCurrentIndex((prevIndex) => prevIndex + 1)
//     }
//   }
//
//   const handlePrev = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex((prevIndex) => prevIndex - 1)
//     }
//   }
//
//   const handlers = useSwipeable({
//     onSwipedLeft: handleNext,
//     onSwipedRight: handlePrev,
//     trackMouse: true,
//   })
//
//   return (
//     <div className="slider-container" {...handlers}>
//       <div
//         className="slider-wrapper"
//         style={{ transform: `translateX(-${currentIndex * 50}%)` }}
//       >
//         {images.map((image, index) => (
//           <div className="slide" key={index}>
//             <img src={image} alt={`Review ${index + 1}`} />
//           </div>
//         ))}
//       </div>
//       <button className="prev-btn" onClick={handlePrev}>
//         ‹
//       </button>
//       <button className="next-btn" onClick={handleNext}>
//         ›
//       </button>
//     </div>
//   )
// }
//
// export default Slider
