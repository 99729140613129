import React from 'react'
import './footer.css'
import { Link } from 'react-scroll'
import {
  FooterLogo,
  Instagram,
  Telegram,
  TikTok,
  YouTube,
} from '../../Assets/Images/Footer'

const Footer = () => {
  return (
    <div className="footer_block">
      <div className="customContainer footer_content">
        <div>
          <img className="footer_logo" src={FooterLogo} alt="FooterLogo" />
        </div>
        <div className="footer_nav_block">
          <Link to="about" smooth={true} duration={500}>
            <p className="footer_nav_link">Про мене</p>
          </Link>
          <Link to="courses" smooth={true} duration={500}>
            <p className="footer_nav_link">Курси</p>
          </Link>
          <Link to="mini-courses" smooth={true} duration={500}>
            <p className="footer_nav_link">Міні-курси та марафони</p>
          </Link>
          <Link to="services" smooth={true} duration={500}>
            <p className="footer_nav_link">Послуги</p>
          </Link>
          <Link to="reviews" smooth={true} duration={500}>
            <p className="footer_nav_link">Відгуки</p>
          </Link>
        </div>
        <div className="footer_soc_block">
          <a
            href="https://www.youtube.com/tarasradzivil"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer_soc_icon" src={YouTube} alt="YouTube" />
          </a>
          <a
            href="https://t.me/+pkG0XedMknwyZDdi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer_soc_icon" src={Telegram} alt="Telegram" />
          </a>
          <a
            href="https://www.tiktok.com/@radziviltaras?_t=8nZrYuQrqoX&_r=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer_soc_icon" src={TikTok} alt="TikTok" />
          </a>
          <a
            href="https://www.instagram.com/tarasradzivil?igsh=MXRiYjBvaXg5dWw2aA%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer_soc_icon" src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
