import React, { useRef, useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg } from '../../Constants'
import style from './contact_services.module.css'
import './contact_services.css'

const ContactServices = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  const toastId = useRef(null)

  const onSubmit = async ({ name, service, comment }) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      let msg = `Заявка на послуги:\nІмʼя - ${name}\nНомер телефону - ${phone}\nПослуга - ${service}`

      let resp = await sendInTg(msg)
      if (resp.ok) {
        toast.update(toastId.current, {
          render: <Toastify type={'success'} />,
          type: 'success',
          icon: false,
          autoClose: 3000,
          hideProgressBar: false,
        })
      }
      if (!resp.ok) {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так...',
          type: 'error',
          autoClose: 7000,
        })
      }

      reset()
      setPhone('')
      setIsBlurredPhone(false)
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 3px 3px rgba(255,0,0,.2)',
  }
  const greenBord = {
    boxShadow: 'inset 0px 0px 3px 3px rgba(100,255,100,.2)',
  }

  return (
    <div className={style.contact_block}>
      <div>
        <div className={`${style.contact_content}`}>
          <form onSubmit={handleSubmit(onSubmit)} className={style.box_form}>
            <div className={style.box_form__box}>
              <div
                className={style.box_form__box_wrap}
                style={
                  errors?.name && touchedFields?.name
                    ? redBord
                    : !errors?.name && touchedFields?.name
                    ? greenBord
                    : null
                }
              >
                <input
                  type="text"
                  id="name"
                  placeholder="Уведіть своє імʼя"
                  className={style.box_form__box_wrap__inp}
                  {...register('name', {
                    required: true,
                    minLength: 2,
                    maxLength: 30,
                  })}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className={style.box_form__box}>
              <div
                className={style.box_form__box_wrap}
                style={
                  !isValid && isBlurredPhone
                    ? redBord
                    : isValid && isBlurredPhone
                    ? greenBord
                    : null
                }
              >
                <PhoneInput
                  defaultCountry="ua"
                  className={style.box_form__box_wrap__telInp}
                  onChange={(e) => setPhone(e)}
                  value={phone}
                  onFocus={() => setIsBlurredPhone(true)}
                />
              </div>
            </div>

            {/* Додаємо селект для вибору послуги */}
            <div className={style.box_form__box}>
              <div
                className={style.box_form__box_wrap}
                style={
                  errors?.service && touchedFields?.service
                    ? redBord
                    : !errors?.service && touchedFields?.service
                    ? greenBord
                    : null
                }
              >
                <select
                  id="service"
                  className={style.box_form__box_wrap__inp}
                  {...register('service', {
                    required: true,
                  })}
                >
                  <option value="">Оберіть послугу</option>
                  <option value="Індивідуальна консультація">
                    Індивідуальна консультація
                  </option>
                  <option value="Фінансова діагностика">
                    Фінансова діагностика
                  </option>
                  <option value="Проведення корпоративного вебінару">
                    Проведення корпоративного вебінару
                  </option>
                  <option value="Проведення корпоративного вебінару">
                    Проведення корпоративного вебінару
                  </option>
                  <option value="Проведення гри Cash flow">
                    Проведення гри Cash flow
                  </option>
                  <option value="Розробка особистого фінансового плану + супровід">
                    Розробка особистого фінансового плану + супровід
                  </option>
                </select>
              </div>
            </div>
            <button className={style.box_form__btn}>Надіслати заявку</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactServices
