import React, { useState } from 'react'
import styles from './MainCourses.module.css'
import Title from '../../Components/Title/Title'
import {
  MainCoursesImg1,
  MainCoursesImg2,
  MainCoursesImg3,
} from '../../Assets/Images/MainCourses/Index'
import PopUp from '../../Components/PopUp/PopUp' // Підключення модульних стилів

const Courses = () => {
  const courses = [
    {
      id: 1,
      title: '“Інвестиції в акції з нуля”',
      description:
        'Перший прибуток та перші дивіденди з фондового ринку США та Європи. Складете свій оптимальний інвестиційний портфель, наповните дивідендні акції та інші цінні папери. Навчитеся вибирати найкращі компанії.',
      duration: 'Термін навчання: 2 місяці',
      buttonText: 'Детальніше',
      imgSrc: MainCoursesImg1, // Додай шляхи до зображень
    },
    {
      id: 2,
      title: '“Фінансова свідомість”',
      description:
        'Найважливіші поради з фінансової грамотності, які повинен знати кожен. 10+ варіантів інвестування Ваших коштів з оптимальним співвідношенням дохідність/ризик. Основи крипторинку.',
      duration: 'Термін навчання: 2 місяці',
      buttonText: 'Детальніше',
      imgSrc: MainCoursesImg2,
    },
    {
      id: 3,
      title: '“Як вирішити пенсійне питання”',
      description:
        'Основна мета курсу – не покладатися на державну пенсію, якої може і не бути, а досягти фінансової незалежності (пенсії) самостійно значно раніше.',
      duration: 'Термін навчання: 3 тижні',
      buttonText: 'Детальніше',
      imgSrc: MainCoursesImg3,
    },
  ]

  const [showPopup, setShowPopup] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState('') // Додаємо стан для назви курсу

  const handleShow = (courseTitle) => {
    setSelectedCourse(courseTitle) // Зберігаємо назву курсу
    setShowPopup(true)
  }
  const handleClose = () => setShowPopup(false)
  return (
    <div className={styles.wrap} id={'courses'}>
      <Title white={'Основні'} blue={'Курси'} />
      <div className={`${'customContainer'} ${styles.container}`}>
        {courses.map((course) => (
          <div key={course.id} className={styles.courseCard}>
            <div className={styles.textWrapper}>
              <h3 className={styles.title}>{course.title}</h3>
              <p className={styles.description}>{course.description}</p>
              <div className={styles.courseCard_wrap}>
                <button
                  onClick={() => handleShow(course.title)}
                  className={styles.button}
                >
                  {course.buttonText}
                </button>
                <p className={styles.duration}>{course.duration}</p>
              </div>
            </div>
            <div className={styles.imageWrapper}>
              <img
                src={course.imgSrc}
                alt={course.title}
                className={styles.image}
              />
            </div>
          </div>
        ))}
      </div>
      <PopUp show={showPopup} handleClose={handleClose} title={selectedCourse}>
        <p>This is the content of the popup.</p>
      </PopUp>
    </div>
  )
}

export default Courses
