import React from 'react'
import style from './MainBlock.module.css'
import { Link } from 'react-scroll'

const MainBlock = () => {
  return (
    <div className={style.wrap}>
      <div className={`${'customContainer'} ${style.mainBlock}`}>
        <div className={style.textContainer}>
          <h1 className={style.title}>
            Фінансова <span className={style.highlight}>грамотність</span> та{' '}
            <span className={style.highlight}>інвестиції</span> для кожного
          </h1>
          <p className={style.desc}>
            Допоможу стати фінансово незалежним за 7 років
          </p>
          <div className={style.buttons}>
            <Link to="courses" smooth={true} duration={500}>
              <button className={style.buttonPrimary}>Переглянути курси</button>
            </Link>
            <Link to="services" smooth={true} duration={500}>
              <button className={style.buttonSecondary}>
                Переглянути послуги{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.28 6.47003C17.4204 6.61066 17.4993 6.80128 17.4993 7.00003C17.4993 7.19878 17.4204 7.38941 17.28 7.53003L11.28 13.53C11.2113 13.6037 11.1285 13.6628 11.0365 13.7038C10.9445 13.7448 10.8452 13.7668 10.7445 13.7686C10.6438 13.7704 10.5438 13.7519 10.4504 13.7142C10.357 13.6764 10.2722 13.6203 10.201 13.5491C10.1297 13.4779 10.0736 13.393 10.0359 13.2996C9.99814 13.2062 9.97962 13.1062 9.9814 13.0055C9.98317 12.9048 10.0052 12.8055 10.0462 12.7135C10.0872 12.6215 10.1463 12.5387 10.22 12.47L14.94 7.75003L0.749988 7.75003C0.551076 7.75003 0.36031 7.67101 0.219658 7.53036C0.0790053 7.38971 -1.17487e-05 7.19894 -1.174e-05 7.00003C-1.17313e-05 6.80112 0.0790053 6.61035 0.219658 6.4697C0.36031 6.32905 0.551076 6.25003 0.749988 6.25003L14.94 6.25003L10.22 1.53003C10.1463 1.46137 10.0872 1.37857 10.0462 1.28657C10.0052 1.19457 9.98317 1.09526 9.9814 0.994554C9.97962 0.893851 9.99814 0.793821 10.0359 0.700433C10.0736 0.607045 10.1297 0.522211 10.201 0.450993C10.2722 0.379774 10.357 0.323629 10.4504 0.285908C10.5438 0.248187 10.6438 0.229663 10.7445 0.23144C10.8452 0.233216 10.9445 0.255258 11.0365 0.29625C11.1285 0.337242 11.2113 0.396344 11.28 0.470031L17.28 6.47003Z"
                    fill="black"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBlock
