import './Assets/Fonts/stylesheet.css'
import './App.css'
import MainBlock from './Modules/MainBlock/MainBlock'
import Header from './Modules/Header/Header'
import AboutMe from './Modules/AboutMe/AboutMe'
import WhyMe from './Modules/WhyMe/WhyMe'
import MainCourses from './Modules/MainCourses/MainCourses'
import MiniCourses from './Modules/MiniCourses/MiniCourses'
import Services from './Modules/Services/Services'
import Reviews from './Modules/Reviews/Reviews'
import Contact from './Modules/Contact/Contact'
import Footer from './Modules/Footer/Footer'
import FooterCopyright from './Modules/Footer/FooterCopyright'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <MainBlock />
      <AboutMe />
      <WhyMe />
      <MainCourses />
      <MiniCourses />
      <Services />
      <Reviews />
      <Contact />
      <Footer />
      <FooterCopyright />
    </div>
  )
}

export default App
