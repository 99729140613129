import React, { useState } from 'react'
import style from './Header.module.css'
import PopUp from '../../Components/PopUp/PopUp' // Підключення стилів

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const [showPopup, setShowPopup] = useState(false)

  const handleShow = () => setShowPopup(true)
  const handleClose = () => setShowPopup(false)

  return (
    <div className={style.wrap}>
      <header className={`${'customContainer'} ${style.header}`}>
        <div className={style.logoContainer}>
          <span className={style.logoText}>ТАРАС</span>
          <span className={style.logoSubtext}>РАДЗІВІЛ</span>
        </div>

        {/* Desktop Navigation */}
        <nav className={style.navWeb}>
          <ul>
            <li>
              <a href="#about">Про мене</a>
            </li>
            <li>
              <a href="#courses">Курси</a>
            </li>
            <li>
              <a href="#mini-courses">Міні-курси</a>
            </li>
            <li>
              <a href="#services">Послуги</a>
            </li>
            <li>
              <a href="#reviews">Відгуки</a>
            </li>
          </ul>
        </nav>

        {/* Mobile Navigation */}

        <button onClick={handleShow} className={style.button}>
          Консультація
        </button>

        {/* Burger Menu for Mobile */}
        <div className={style.burgerMenu} onClick={toggleMenu}>
          <div
            className={`${style.burger} ${isMenuOpen ? style.burgerOpen : ''}`}
          ></div>
        </div>
      </header>
      <nav className={`${style.navMobile} ${isMenuOpen ? style.open : ''}`}>
        <ul>
          <li>
            <a href="#about">Про мене</a>
          </li>
          <li>
            <a href="#courses">Курси</a>
          </li>
          <li>
            <a href="#mini-courses">Міні-курси</a>
          </li>
          <li>
            <a href="#services">Послуги</a>
          </li>
          <li>
            <a href="#reviews">Відгуки</a>
          </li>
          <li>
            <button
              onClick={handleShow}
              className={`${style.button} ${style.mbButton}`}
            >
              Консультація
            </button>
          </li>
        </ul>
      </nav>
      {isMenuOpen && <div className={style.overlay} onClick={toggleMenu}></div>}
      <PopUp show={showPopup} handleClose={handleClose}>
        <p>This is the content of the popup.</p>
      </PopUp>
    </div>
  )
}

export default Header
