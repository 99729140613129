import React from 'react'
import style from './WhyMe.module.css'
import Title from '../../Components/Title/Title'
import { WhyMeImg1, WhyMeImg2, WhyMeImg3, WhyMeImg4 } from '../../Assets/Images/WhyMe/Index'

const data = [
  {
    icon: WhyMeImg1, // Іконку можна передати через SVG або шляхи до файлу
    text: 'БІЛЬШЕ 15 РОКІВ У ФІНАНСОВОМУ ПОРАДНИЦТВІ',
  },
  {
    icon: WhyMeImg2, // Іконку можна передати через SVG або шляхи до файлу
    text: '7 АВТОРСЬКИХ ОСВІТНІХ ПРОДУКТІВ',
  },
  {
    icon: WhyMeImg3, // Іконку можна передати через SVG або шляхи до файлу
    text: '30000+ ПІДПИСНИКІВ У СОЦМЕРЕЖАХ',
  },
  {
    icon: WhyMeImg4, // Іконку можна передати через SVG або шляхи до файлу
    text: '10000+ ГОДИН РОБОТИ З КЛІЄНТАМИ',
  },
]

const WhyMe = () => {
  return (
    <div className={style.whyMe}>
    <div className={`${'customContainer'} ${style.wrap}`}>
      <Title white={'Чому'} blue={'я?'}/>

      <div className={style.items}>
        {data.map((item, index) => (
          <div key={index} className={style.item}>
            <img src={item.icon} alt="icon" className={style.icon} />
            <p className={style.text}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default WhyMe
