import React, { useState } from 'react'
import './minicourses.css'
import {
  MiniCursIcon1,
  MiniCursIcon2,
  MiniCursIcon3,
  MiniCursIcon4,
} from '../../Assets/Images/MiniCurs'
import PopUp from '../../Components/PopUp/PopUp'

const coursesData = [
  {
    title: 'Криптомарафон',
    description: 'Розбираємось у всіх тонкощах інвестування в крипторинок.',
    duration: 'Термін навчання: 3 дні',
    imgSrc: MiniCursIcon1,
    imgAlt: 'MiniCursIcon1',
  },
  {
    title: 'Фінансовий трикутник',
    description:
      'Фінансова грамотність, фондовий ринок, криптовалюта - 3 складові руху до фінансової свободи.',
    duration: 'Термін навчання: 3 дні',
    imgSrc: MiniCursIcon2,
    imgAlt: 'MiniCursIcon2',
  },
  {
    title: 'Інвестиції 2024',
    description:
      'Розбираємо 15+ шляхів інвестування у цьому році. Куди прибутково і надійно вкладати свої кошти.',
    duration: 'Термін навчання: 3 дні',
    imgSrc: MiniCursIcon3,
    imgAlt: 'MiniCursIcon3',
  },
  {
    title: 'Пенсія по-європейськи',
    description:
      'Як вирішити пенсійне питання вже сьогодні? Як отримати таку пенсію, яку Ви хочете, а не ту, яку дасть держава?',
    duration: 'Термін навчання: 3 дні',
    imgSrc: MiniCursIcon4,
    imgAlt: 'MiniCursIcon4',
  },
]

const MiniCourses = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState('') // Додаємо стан для назви курсу

  const handleShow = (courseTitle) => {
    setSelectedCourse(courseTitle) // Зберігаємо назву курсу
    setShowPopup(true)
  }

  const handleClose = () => setShowPopup(false)
  return (
    <div className="minicurs_block" id={'mini-courses'}>
      <div className="customContainer">
        <div className="minicurs_title_block">
          <div className="minicurs_title_line"></div>
          <h2 className="minicurs_title">
            Міні курси та <span className="minicurs_title_span">марафони</span>{' '}
          </h2>
          <div className="minicurs_title_line"></div>
        </div>

        <div className="minicurs_content">
          {coursesData.map((course, index) => (
            <div key={index} className="minicurs_card">
              <div className="minicurs_card_top">
                <p className="minicurs_card_top_title">{course.title}</p>
                <p className="minicurs_card_top_desc">{course.description}</p>
              </div>
              <div className="minicurs_card_bottom">
                <button
                  onClick={() => handleShow(course.title)}
                  className="minicurs_card_btn"
                >
                  Детальніше
                </button>
                <p className="minicurs_card_time">{course.duration}</p>
              </div>
              <img
                className="minicurs_card_icon"
                src={course.imgSrc}
                alt={course.imgAlt}
              />
            </div>
          ))}
        </div>
      </div>
      <PopUp show={showPopup} handleClose={handleClose} title={selectedCourse}>
        <p>This is the content of the popup.</p>
      </PopUp>
    </div>
  )
}

export default MiniCourses
