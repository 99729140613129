import React from 'react'
import styles from './Toastify.module.css'
import { Spinner } from 'react-bootstrap'
import { iconDone } from '../../Assets/Icons/icons'

const Toastify = ({ type }) => {
  if (type === 'pending') {
    return (
      <div className={styles.wrap}>
        <Spinner color="default" />
        <div className={styles.wrap_text}>
          <h3 className={styles.wrap_text__title}>Надсилаємо заявку</h3>
          <p className={styles.wrap_text__subTitle}>
            Триває процес надсилання заявки...
          </p>
        </div>
      </div>
    )
  }
  if (type === 'success') {
    return (
      <div className={styles.wrap}>
        <img src={iconDone} alt="done" loading={'lazy'} />
        <div className={styles.wrap_text}>
          <h3 className={styles.wrap_text__title}>Заявку надіслано!</h3>
          <p className={styles.wrap_text__subTitle}>
            Ваша заявка була успішно надіслана, наш менеджер зв'яжеться з вами
            найближчим часом
          </p>
        </div>
      </div>
    )
  }
}

export { Toastify }
