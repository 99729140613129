import React from 'react'
import styles from './AboutMe.module.css'
import Title from '../../Components/Title/Title'
import { AboutMeImg } from '../../Assets/Images/AboutMe' // Імпорт модулів CSS

const AboutMe = () => {
  const achievements = [
    'Cертифікований тьютор по фінансовій грамотності на інвестуванню від IAPBE (Альянс Професійальних Бізнес-еліт, Лондон, Великобританія)',
    'Cертифікований незалежний фінансовий радник з 2009 року (OVB VermogensBeratung A.G., Кельн, Німеччина)',
    'Навчив 2000+ людей інвестуванню на фондовому ринку',
    'Автор книги “Із боржника в інвестори” та 7 брошур на фінансову тематику',
    'Понад 10000 годин безпосередньої роботи з клієнтами',
    '500+ розроблених особистих фінансових планів',
    'Роздрібний інвестор',
  ]
  return (
    <div className={styles.aboutMe} id={'about'}>
      <Title white={'Про'} blue={'мене'} />
      <div className={`${'customContainer'} ${styles.wrap}`}>
        <div className={styles.imageContainer}>
          <img
            className={styles.profileImage}
            src={AboutMeImg} // Вставте шлях до вашого зображення
            alt="Profile"
          />
        </div>
        <div className={styles.infoContainer}>
          <ul className={styles.infoList}>
            {achievements.map((achievement, index) => (
              <li key={index}>{achievement}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
