import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PhoneInput } from 'react-international-phone'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg } from '../../Constants'
import style from './PopupForm.module.css'
import './modal.css'
import { CloseBtn } from '../../Assets/Icons/icons'

const PopUp = ({ show, handleClose, title }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }

  const isValid = isPhoneValid(phone)
  const toastId = useRef(null)

  const onSubmit = async ({ name }) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      // Додаємо назву курсу в повідомлення
      let msg = `Нова заявка:\nКурс - ${title}\nName - ${name}\nPhone - ${phone}`

      let resp = await sendInTg(msg)
      if (resp.ok) {
        toast.update(toastId.current, {
          render: <Toastify type={'success'} />,
          type: 'success',
          icon: false,
          autoClose: 3000,
          hideProgressBar: false,
        })
      }
      handleClose()
      if (!resp.ok) {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так...',
          type: 'error',
          autoClose: 7000,
        })
      }

      reset()
      setPhone('')
      setIsBlurredPhone(false)
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
  }
  const greenBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      {/*<Modal.Header closeButton></Modal.Header>*/}
      <div className={style.popup_block}>
        <Modal.Body>
          <button className={style.popup_close_btn} onClick={handleClose}>
            <img src={CloseBtn} alt="" />
          </button>
          <div className={style.wrap}>
            <div>
              <div className={style.box}>
                <div className={style.box_texts}>
                  <h2 className={style.box_texts__title}>
                    Залиште дані для консультації
                  </h2>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={style.box_form}
                >
                  <div className={style.box_form__box}>
                    <label htmlFor="Phone" className={style.form_box_label}>
                      Імʼя
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors?.name && touchedFields?.name
                          ? redBord
                          : !errors?.name && touchedFields?.name
                          ? greenBord
                          : null
                      }
                    >
                      <input
                        type="text"
                        id="name"
                        placeholder="Уведіть ваше імʼя"
                        className={style.box_form__box_wrap__inp}
                        {...register('name', {
                          required: false,
                          minLength: 2,
                          maxLength: 30,
                        })}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className={style.box_form__box}>
                    <label htmlFor="Phone" className={style.form_box_label}>
                      Номер телефону
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        !isValid && isBlurredPhone
                          ? redBord
                          : isValid && isBlurredPhone
                          ? greenBord
                          : null
                      }
                    >
                      <PhoneInput
                        defaultCountry="ua"
                        className={style.box_form__box_wrap__telInp}
                        onChange={(e) => setPhone(e)}
                        value={phone}
                        onFocus={() => setIsBlurredPhone(true)}
                      />
                    </div>
                  </div>
                  <button className={style.box_form__btn}>
                    Надіслати заявку
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default PopUp
